<template>
  <div v-if="currentMariage" class="edit-form">
    <h4>Mariage</h4>
    <form>
      <div class="form-group">
        <label for="libelle">Libellé</label>
        <input type="text" class="form-control" id="libelle"
          v-model="currentMariage.libelle"
        />
      </div>
      <div class="form-group">
        <label for="dateDot">Date de Dot</label>
        <input type="date" class="form-control" id="dateDot"
          v-model="currentMariage.dateDot"
        />
      </div>
      <div class="form-group">
        <label for="lieuDot">Lieu de Dot</label>
        <input type="text" class="form-control" id="lieuDot"
          v-model="currentMariage.lieuDot"
        />
      </div>

      <div class="form-group">
        <label for="dateCivil">Date de Civil</label>
        <input type="date" class="form-control" id="dateCivil"
          v-model="currentMariage.dateCivil"
        />
      </div>
      <div class="form-group">
        <label for="lieuCivil">Lieu de Civil</label>
        <input type="text" class="form-control" id="lieuCivil"
          v-model="currentMariage.lieuCivil"
        />
      </div>
      
      <div class="form-group">
        <label for="dateReligieux">Date de Religieux</label>
        <input type="date" class="form-control" id="dateReligieux"
          v-model="currentMariage.dateReligieux"
        />
      </div>
      <div class="form-group">
        <label for="lieuReligieux">Lieu de Religieux</label>
        <input type="text" class="form-control" id="lieuReligieux"
          v-model="currentMariage.lieuReligieux"
        />
      </div>

      <div class="form-group">
        <label for="user">Utilisateur</label>
        <input class="form-control" id="user"
          v-model="currentMariage.user"
        />
      </div>

      <div class="form-group">
        <label><strong>Statut Dot:</strong></label>
        {{ currentMariage.estDotConfirme ? "Officiel" : "Prévisionnel" }}
      </div>
      <div class="form-group">
        <label><strong>Statut Civil:</strong></label>
        {{ currentMariage.estCivilConfirme ? "Officiel" : "Prévisionnel" }}
      </div>
      <div class="form-group">
        <label><strong>Statut Religieux:</strong></label>
        {{ currentMariage.estReligieuxConfirme ? "Officiel" : "Prévisionnel" }}
      </div>
    </form>

    <button class="badge badge-primary mr-2"
      v-if="currentMariage.estDotConfirme"
      @click="updateConfirmedDot(false)"
    >
      Prévoir Dot
    </button>
    <button v-else class="badge badge-primary mr-2"
      @click="updateConfirmedDot(true)"
    >
      Confirmer Dot
    </button>

    <button class="badge badge-primary mr-2"
      v-if="currentMariage.estCivilConfirme"
      @click="updateConfirmedCivil(false)"
    >
      Prévoir Civil
    </button>
    <button v-else class="badge badge-primary mr-2"
      @click="updateConfirmedCivil(true)"
    >
      Confirmer Civil
    </button>

    <button class="badge badge-primary mr-2"
      v-if="currentMariage.estCivilConfirme"
      @click="updateConfirmedReligieux(false)"
    >
      Prévoir Religieux
    </button>
    <button v-else class="badge badge-primary mr-2"
      @click="updateConfirmedReligieux(true)"
    >
      Confirmer Religieux
    </button>


    <button class="badge badge-danger mr-2"
      @click="deleteMariage"
    >
      Supprimer
    </button>

    <button type="submit" class="badge badge-success"
      @click="updateMariage"
    >
      Mettre à jour
    </button>
    <p>{{ message }}</p>
  </div>

  <div v-else>
    <br />
    <p>Veuillez cliquer sur un Mariage SVP...</p>
  </div>
</template>

<script>
import MariageDataService from "../services/MariageDataService";

export default {
  name: "mariage",
  data() {
    return {
      currentMariage: null,
      message: ''
    };
  },
  methods: {
    getMariage(id) {
      MariageDataService.get(id)
        .then(response => {
          this.currentMariage = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateConfirmedDot(status) {
      var data = {
        id: this.currentMariage.id,
        lieuDot: this.currentMariage.lieuDot,
        dateDot: this.currentMariage.dateDot,
        estDotConfirme: status
      };

      MariageDataService.update(this.currentMariage.id, data)
        .then(response => {
          console.log(response.data);
          this.currentMariage.estDotConfirme = status;
          this.message = 'The status was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateConfirmedCivil(status) {
      var data = {
        id: this.currentMariage.id,
        lieuCivil: this.currentMariage.lieuCivil,
        dateCivil: this.currentMariage.dateCivil,
        estCivilConfirme: status
      };

      MariageDataService.update(this.currentMariage.id, data)
        .then(response => {
          console.log(response.data);
          this.currentMariage.estCivilConfirme = status;
          this.message = 'The status was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateConfirmedReligieux(status) {
      var data = {
        id: this.currentMariage.id,
        lieuReligieux: this.currentMariage.lieuReligieux,
        dateReligieux: this.currentMariage.dateReligieux,
        estReligieuxConfirme: status
      };

      MariageDataService.update(this.currentMariage.id, data)
        .then(response => {
          console.log(response.data);
          this.currentMariage.estReligieuxConfirme = status;
          this.message = 'The status was updated successfully!';
        })
        .catch(e => {
          console.log(e);
        });
    },

    updateMariage() {
      MariageDataService.update(this.currentMariage.id, this.currentMariage)
        .then(response => {
          console.log(response.data);
          this.message = "Les informations du mariage ont été mises à jour avec succès !";
        })
        .catch(e => {
          console.log(e);
        });
    },

    deleteMariage() {
      MariageDataService.delete(this.currentMariage.id)
        .then(response => {
          console.log(response.data);
          this.$router.push({ name: "mariages" });
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.message = '';
    this.getMariage(this.$route.params.id);
  }
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>





libelle: String,
    dateDot: Date,
    lieuDot:  String,
    estDotConfirme: Boolean,
    dateCivil: Date,
    lieuCivil:  String,
    estCivilConfirme: Boolean,
    dateReligieux: Date,
    lieuReligieux: String,
    estReligieuxConfirme: Boolean,
    user: {
        type: mongoose.Schema.Types.ObjectId,
        ref: "User"
    }